import { makeAutoObservable, runInAction } from "mobx";
import instance from "./instance";

class ProductStore {
  products = [];
  reports = [];
  loading = true;

  constructor() {
    makeAutoObservable(this);
  }
  fetchProduct = async () => {
    try {
      const resP = await instance.get(`/products`);
      runInAction(() => {
        this.products = resP.data;
        this.loading = false;
      });
    } catch (error) {
      console.error("fetchProduct: ", error);
    }
  };
  fetchreport = async () => {
    try {
      // const resP = await axios.get(
      //   `http://localhost:8080/reports/product-reports`
      // );
      const resP = await instance.get(`/reports/product-reports`);
      runInAction(() => {
        this.reports = resP.data;
        this.loading = false;
      });
    } catch (error) {
      console.error("fetchProduct:sss ", error);
    }
  };
  deleteProduct = async (data, productId) => {
    try {
      await instance.delete(`/products/${productId}`);
      runInAction(() => {
        this.products = data;
      });
    } catch (error) {
      console.log("delete product", error);
    }
  };
  setProducts = async (data) => {
    try {
      runInAction(() => {
        this.products = data;
      });
    } catch (error) {
      console.log("delete product", error);
    }
  };
  activateProduct = async (productId) => {
    try {
      await instance.put(`/products/activate/${productId}`);

      runInAction(() => {
        // product.active = !product.active;
        this.products = this.products.map((product) => {
          if (product.id === productId) {
            product.active = !product.active;
            return product;
          } else {
            return product;
          }
        });
      });
    } catch (error) {}
  };
  featuredProduct = async (productId) => {
    try {
      await instance.put(`/products/featured/${productId}`);

      runInAction(() => {
        this.products = this.products.map((product) => {
          if (product.id === productId) {
            product.featured = !product.featured;
            return product;
          } else {
            return product;
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  addProduct = async (data) => {
    try {
      const formData = new FormData();
      for (const key in data) formData.append(key, data[key]);
      const res = await instance.post("/products", formData);
      runInAction(() => {
        this.products.push(res.data);
        // this.fetchProduct();
      });
    } catch (error) {
      console.log("new product", error);
    }
  };
  updateProduct = async (data, productId) => {
    try {
      const formData = new FormData();
      for (const key in data) formData.append(key, data[key]);
      await instance.put(`/products/${productId}`, formData);
      const product = this.products.find((product) => product.id === productId);
      //   const products = this.products.filter(
      //     (product) => product.id !== productId
      //   );
      let updatedProduct = data;
      if (data.image && typeof data.image !== "string") {
        updatedProduct = {
          ...data,
          image: window.URL.createObjectURL(data.image),
        };
      }
      runInAction(() => {
        // this.products = products;
        // this.products.push(product);
        for (const key in updatedProduct) product[key] = updatedProduct[key];
      });
    } catch (error) {
      console.log("update product", error);
    }
  };
  getProductById = (productId) =>
    this.products.find((product) => product.id === productId);
  getProductById = (productId) =>
    this.products.find((product) => product.id === productId);
}
const productStore = new ProductStore();
productStore.fetchProduct();
productStore.fetchreport();
export default productStore;
