import { makeAutoObservable, runInAction } from "mobx";
import instance from "./instance";

class InvoiceStore {
  invoices = [];
  loading = true;
  value = 30;

  constructor() {
    makeAutoObservable(this);
  }
  fetchInvoices = async (value) => {
    try {
      const res = await instance.get(
        `/invoices?date=${new Date()}&value=${this.value}`
      );
      runInAction(() => {
        this.invoices = res.data;
        this.loading = false;
      });
    } catch (error) {
      console.error("fetchInvoice: ", error);
    }
  };
  setValue = (value) => {
    runInAction(() => {
      this.value = value;
    });
    this.fetchInvoices();
  };
  deleteInvoice = async (data, invoiceId) => {
    try {
      await instance.delete(`/invoices/${invoiceId}`);
      runInAction(() => {
        this.invoices = data;
      });
    } catch (error) {
      console.log("delete invoice", error);
    }
  };

  paidInvoice = async (invoiceId) => {
    try {
      await instance.put(`/invoices/paid/${invoiceId}`);
      runInAction(() => {
        this.invoices = this.invoices.map((invoice) => {
          if (invoice.id === invoiceId) {
            invoice.paid = !invoice.paid;
            return invoice;
          } else {
            return invoice;
          }
        });
      });
    } catch (error) {}
  };
  // addInvoice = async (data) => {
  //   try {
  //     const res = await instance.post("/invoices", data);
  //     runInAction(() => {
  //       this.invoices.push(res.data);
  //     });
  //   } catch (error) {
  //     console.log("new invoice", error);
  //   }
  // };
  setStatus = async (status, id) => {
    const data = { status: status };
    try {
      await instance.put(`/invoices/${id}`, data);
      const invoice = this.invoices.find((invoice) => invoice.id === id);

      runInAction(() => {
        invoice.status = status;
      });
    } catch (error) {
      console.log("new invoice", error);
    }
  };

  getInvoiceById = (invoiceId) =>
    this.invoices.find((invoice) => invoice.id === invoiceId);
}
const invoiceStore = new InvoiceStore();
invoiceStore.fetchInvoices();
export default invoiceStore;
