import antdArAR from "antd/es/locale/ar_EG";
import arMsg from "../locales/ar_AR.json";

const ARLang = {
  antd: antdArAR,
  locale: "ar-AR",
  messages: {
    ...arMsg,
  },
};
export default ARLang;
