import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  // EnvironmentOutlined,
  // AntDesignOutlined,
  // InfoCircleOutlined,
  // CompassOutlined,
  // LayoutOutlined,
  // DesktopOutlined,
  // FileDoneOutlined,
  // CommentOutlined,
  RobotOutlined,
  // PlusCircleOutlined,
  // FundOutlined,
  // ShoppingCartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboards-default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    title: "dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];
const ordersNavTree = [];

const appsNavTree = [
  {
    key: "ecommerce",
    path: `${APP_PREFIX_PATH}/ecommerce`,
    title: "ecommerce",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce-categoryList",
        path: `${APP_PREFIX_PATH}/ecommerce/category-list`,
        title: "categories",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "apps-ecommerce-productList",
        path: `${APP_PREFIX_PATH}/ecommerce/product-list`,
        title: "products",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-ecommerce-productreports",
        path: `${APP_PREFIX_PATH}/ecommerce/product-reports`,
        title: "productsReports",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "apps-ecommerce-inventory",
        path: `${APP_PREFIX_PATH}/ecommerce/inventory`,
        title: "inventory",
        icon: "",
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
  {
    key: "apps-ecommerce-orders",
    path: `${APP_PREFIX_PATH}/ecommerce/orders`,
    title: "orders",
    icon: FileTextOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce-all-orders",
        path: `${APP_PREFIX_PATH}/ecommerce/orders`,
        title: "all",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },

  {
    key: "apps-ecommerce-Alalytics",
    path: `${APP_PREFIX_PATH}/dashboards/analytics`,
    title: "analytics",
    icon: PieChartOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce-Reports",
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        title: "reports",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "apps-ecommerce-best-selling",
        path: `${APP_PREFIX_PATH}/dashboards/best-selling`,
        title: "bestSelling",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "apps-ecommerce-Marketing",
    path: `${APP_PREFIX_PATH}/dashboards/marketing`,
    title: "marketing",
    icon: RobotOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce-Voucher",
        path: `${APP_PREFIX_PATH}/ecommerce/voucher-list`,
        title: "vouchers",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },

  {
    key: "business-info-setup",
    path: `${APP_PREFIX_PATH}/setup/edit-profile`,
    title: "setup",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "business-info-edit-profile",
        path: `${APP_PREFIX_PATH}/setup/edit-profile`,
        title: "profile",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "business-info-edit-gateways",
        path: `${APP_PREFIX_PATH}/setup/edit-gateways`,
        title: "paymentGateways",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "business-info-edit-web-links",
        path: `${APP_PREFIX_PATH}/setup/edit-web-links`,
        title: "weblinks",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "business-info-staff-list",
      //   path: `${APP_PREFIX_PATH}/setup/staff-list`,
      //   title: "sidenav.apps.staff-list",
      //   icon: "",
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "apps-ecommerce-banners",
        path: `${APP_PREFIX_PATH}/ecommerce/banners`,
        title: "banners",
        icon: "",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...appsNavTree,
  ...ordersNavTree,
  // ...extraNavTree,
];

export default navigationConfig;
