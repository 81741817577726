import { makeAutoObservable, runInAction } from "mobx";
import instance from "./instance";

class ReportStore {
  daily_reports = {};
  monthly_reports = {};
  yearly_reports = {};
  last_monthly_reports = {};
  best_selling = { categories: {} };
  loadingD = true;
  loadingS = true;
  loadingM = true;

  constructor() {
    makeAutoObservable(this);
  }
  DailyReport = async (date) => {
    try {
      this.loadingD = true;
      this.loadingM = true;

      const d = new Date();
      const resDaily = await instance.get(
        `/reports?day=today&date=${new Date()}`
      );
      const resYealy = await instance.get(`/reports?yearly=this-year`);
      const resMonthly = await instance.get(
        `/reports?date=${date ? date : new Date()}`
      );
      const resLastMonthly = await instance.get(
        `/reports?date=${new Date(
          d.getFullYear(),
          d.getMonth() - 1,
          d.getDate()
        )}`
      );
      runInAction(() => {
        this.daily_reports = resDaily.data;
        this.yearly_reports = resYealy.data;
        this.monthly_reports = resMonthly.data;
        this.last_monthly_reports = resLastMonthly.data;

        this.loadingM = false;
        this.loadingD = false;
      });
    } catch (error) {
      console.error("fetchReport: ", error);
    }
  };
  MonthlyReport = async (date) => {
    try {
      this.loadingM = true;

      const res = await instance.get(
        `/reports?date=${date ? date : new Date()}`
      );
      runInAction(() => {
        this.monthly_reports = res.data;
        this.loadingM = false;
      });
    } catch (error) {
      console.error("fetchReport: ", error);
    }
  };
  BestSelling = async (date) => {
    try {
      this.loadingS = true;

      const res = await instance.get(
        `/reports/best-selling?date=${date ? date : new Date()}`
      );
      runInAction(() => {
        this.best_selling = res.data;
        this.loadingS = false;
      });
    } catch (error) {
      console.error("fetchReport: ", error);
    }
  };
}
const reportStore = new ReportStore();
reportStore.DailyReport();
reportStore.MonthlyReport();
reportStore.BestSelling();
export default reportStore;
